<template>
    <Page class="feedback-page">
        <template v-slot:title>
            Обратная связь
        </template>
        <div class="feedback-page__content">
            <FormTemplate
                action="Отправить сообщение"
            >
                <fieldset>
                    <Phone
                        title="Телефон"
                    />
                    <Field
                        title="E-mail"
                        placeholder="user@mail.ru"
                    />
                </fieldset>
                <Field
                    title="Сообщение"
                    textarea
                    placeholder="Отличный сервис!"
                />
            </FormTemplate>
        </div>
    </Page>
</template>

<script>
import Page from '@/applications/personal-area/layouts/page/Page'
import Phone from '@/components/form/field/Phone'

import './feedback-page.scss'

export default {
    name: 'Feedback',
    components: {
        FormTemplate: () => import('@/templates/form/Form'),
        Phone,
        Page
    }
}
</script>