<template>
    <div class="pa-page">
        <Header class="pa-page__header" v-if="isMenu"></Header>
        <h1
            v-if="$slots.title"
            class="pa-page__title"
        >
            <slot name="title"/>
        </h1>
        <div class="pa-page__content">
            <slot/>
        </div>
    </div>
</template>

<script>
import './pa-page.scss'
import Header from '@/components/common/header/Header';
import mobileMixin from '@/mixins/mobile';

export default {
    name: 'PersonalAreaPage',
    mixins: [mobileMixin],
    components: {
        Header,
    }
}
</script>